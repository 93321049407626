import React from "react";
import Transcript from "./Transcript";


const Result = props => {
  console.log('Result props:: ', props);
  return (
    <>
      <div className="resultHoder">
        <h2>Quiz Results</h2>
        <p>{props.message}</p>
        <p>Your score was {props.score}%</p>
        <button className="btn-action submitQuestionButton" onClick={props.restart}>
          {props.passQuiz ? "Restart Quiz" : "Try Again"}
        </button>
        
      </div>
      {/* {props.passQuiz && <Transcript transcript={props.transcript} score={props.score} category={props.category} />} */}
      <Transcript transcript={props.transcript} score={props.score} category={props.category} />
      
    </>
  );
};

export default Result;
