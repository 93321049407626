import React, {useContext, useEffect} from "react";
import FirebaseContext from '../../contexts/firebase/firebaseContext';
import ShowTranscript from './ShowTranscript';


const Transcript = ({ transcript, score, category }) => {
  const context = useContext(FirebaseContext);
  const {user} = context;
  // console.log('transcrit context user:: ', user);
  const insertTranscript = async () => {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date+' '+time;
    transcript &&  await context.firebaseService.insertNewRow(
                    {records: transcript, userUid: user.uid, score, category, date: dateTime}, //save date
                    'transcripts', 
                    `${user.uid}_${Date.now()}`
                  ) ;
  };
  useEffect(() => {
    insertTranscript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript]);

  return (
    <>
      <div className="transcriptContent">
        <h3>Transcript</h3>
        <ShowTranscript transcript={transcript}/>
      </div>
    </>
  );
};

export default Transcript;
