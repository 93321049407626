import React from "react";
import { Navbar, Form, Button } from 'react-bootstrap';
import logo from '../pics/AirsportsLogo.png';
const NavigationBar = (props) => {
return (<Navbar expand="xxl">
    <Navbar.Brand href="#home">
        <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
        /> <span style={{color: '#edd900'}}>{props.user.displayName && props.user.displayName.substring(0, props.user.displayName.lastIndexOf("@"))}</span>
    </Navbar.Brand>
    <Form inline>
        <Button variant="outline-warning" onClick={props.handleLogout}>Logout</Button>
    </Form>                 
</Navbar>);
}

export default NavigationBar;