import React, { useState, useEffect } from "react";
import "./AppLogin.css";
// import "./AppLogin.1.css";
// import fire from './config/firebaseConfig';
import firebaseService from './services/firebaseService';
import FirebaseProvider from './contexts/firebase/firebaseProvider';
import Login from "./Components/Login";
import Home from "./Components/Home";

const App = () => {

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError]= useState('');
  const [hasAccount, setHasAccount] = useState(true);
  
  const clearInputs = () => {
    setEmail('');
    setPassword('');
  }

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
  }

  const handleLogin = () => {
    clearErrors();
    firebaseService.auth.signInWithEmailAndPassword(email, password)
    .then(function(result) {
      // console.log('login result:: ', result);
      if (result.user.displayName === null) {
        return result.user.updateProfile({
                displayName: email
              })
      }
      return true;
    })
    .catch(err => {
      switch(err.code) {
        case "auth/invalid-email":
        case "auth/user-disabled":
        case "auth/user-not-found":
          setEmailError(err.message);
          break;
        case "auth/wrong-password":
          setPasswordError(err.message);
          break;
        default: 
          setEmailError(err.message);
          break;
      }
    })
  };

  //pachetele de primavara si oez cu fructe de mare

  const handleSignUp =  () => {
    clearErrors();
    firebaseService.auth.createUserWithEmailAndPassword(email, password)
    .then(async function(result) {
      return result.user;
    })
    .catch(err => {
      switch(err.code) {
        case "auth/email-already-in-use":
        case "auth/invalid-email":
          setEmailError(err.message);
          break;
        case "auth/weak-password":
          setPasswordError(err.message);
          break;
        default: 
          setEmailError(err.message);
          break;          
      }
    })
  };

  const handleLogout = () => {
    firebaseService.auth.signOut();
  };

  const authListener = () => {
    firebaseService.auth.onAuthStateChanged(async user => {
      if (user) {
        console.log('user:: ', user);
        if (user.displayName === null) {
          await user.updateProfile({
                  displayName: user.email
                });
          console.log('displayName was null at signup');
        }
        clearInputs();
        setUser(user);
      }
      else {
        setUser(null);
      }
    })
  }

  useEffect(()=> {
    authListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    
      <div className="Application">
        {
        user 
        ? (
          <FirebaseProvider firebaseService={firebaseService} user={user}>
            <Home handleLogout={handleLogout} user={user}/>
          </FirebaseProvider>
          )
        : (<Login email={email} setEmail={setEmail} password={password} setPassword={setPassword} handleLogin={handleLogin} handleSignUp={handleSignUp} hasAccount={hasAccount} setHasAccount={setHasAccount} emailError={emailError} passwordError={passwordError} />) 
        }
      </div>
    

  );
};

export default App;
