import React, {useState, useContext, useEffect} from 'react';
import QuestionForm from "./QuestionForm";
import FirebaseContext from '../contexts/firebase/firebaseContext';
import { useQuiz } from "../hooks/useQuiz";
import { Button, Accordion, Card, ListGroup, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from './NavBar';

const AdminHomepage = (props) => {
    const context = useContext(FirebaseContext);
    const [questions, setAllQuestions] = useState([]);
    const [quiz, loading] = useQuiz();



    useEffect(() =>{
        const setQuiz = async () => {        
            quiz.questions && setAllQuestions([...quiz.questions])
        };
        setQuiz();
    }, [quiz]);
    
    console.log('context in adminhomepage::: ', context);
    console.log('quizz.questions in adminhomepage:: ', questions);

    const newQuestionHandler = async (q) => {
        console.log('add q: ', q);
        const questionsIds = questions.map(question => question.id);
        console.log('questionsIds::: ', questionsIds);
        if (questionsIds.length > 0) {
            let biggestId = Math.max(...questionsIds);
            q.id = biggestId+1;
        }
        else {
            q.id = 1;
        }
        let picUrl = false;
        if (q.picture) {
            console.log('uploading picture...');
            picUrl = await context.firebaseService.uploadFile(q.picture, q.id, 'questions');
            
        }
        console.log('picUrl:: ', picUrl);
        const stamp = Math.floor(Date.now() / 1000);
        await context.firebaseService.insertNewRow({...q, picture: picUrl}, 'questions', `q${q.id}`);
        await context.firebaseService.insertNewRow({stamp }, 'v', `${stamp}`);
        //insert new Version
        setAllQuestions([...questions, {...q, picture: picUrl}]);
    };

    const deleteQuestionHandler = async (q) => {
        console.log('del q:: ', q);
        await context.firebaseService.deleteRow(`q${q.id}`, 'questions');
        //insert new version
        let tempQuestions = [...questions];
        const foundIndex = tempQuestions.findIndex(tq => tq.id === q.id);
        console.log('tempQuestions:: ', tempQuestions);
        console.log('found:: ', foundIndex);
        if (foundIndex >=0 ) {
            
            tempQuestions.splice(foundIndex, 1);
            // setAllQuestions([...tempQuestions.map( (tq, i) => { tq.id = i; return tq;} )])
            setAllQuestions(tempQuestions);
        }
        const stamp = Math.floor(Date.now() / 1000);
        await context.firebaseService.insertNewRow({stamp }, 'v', `${stamp}`);
    };

    return (

        <div>
            <NavigationBar user={props.user} handleLogout={props.handleLogout}/>
            {loading ? (
                <span>Loading...</span>
            ) : (
                <div className="containerWrap">
                    <div className="formContainer">
                        <div className="questionFormContainer">
                            <QuestionForm newQuestionHandler={newQuestionHandler} />
                        </div>

                        <div className="viewQuestionsContainer">
                            <ul className="list-group">
                                {
                                    questions
                                        .sort((a,b) => a.id - b.id)
                                        .map(q => {
                                        return (
                                            <li className="list-group-item" key={q.id}>
                                                <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey={q.id}>
                                                        <span>{q.id}. {q.text}</span>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={q.id}>
                                                    <Card.Body>
                                                        <ListGroup>
                                                        {
                                                                
                                                            q.answers.map((a, i)=> <ListGroup.Item key={i} active={a.correct}> {a.text}</ListGroup.Item>)
                                                        }
                                                        </ListGroup>
                                                        {q.picture && <Image src={q.picture} fluid />}
                                                    </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                </Accordion>                                        
                                                <Button className="btn-danger float-right" onClick={() => deleteQuestionHandler(q)}>-</Button>
                                            </li>
                                        );
                                    })
                                }
                            </ul>

                        </div>
                    </div>                    
                </div>
                    
            )}
        </div>

    );
}


export default AdminHomepage;