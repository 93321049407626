import FirebaseContext from './firebaseContext';
import React, { Component } from 'react';

export default class FirebaseProvider extends Component {

    constructor(props){
        super(props);
        console.log('FirebaseProvider props:: ', props);
        try {

            this.state = {
                firebaseService: {
                    'pls':'work',
                    ...this.props.firebaseService
                },
                user: {
                    ...this.props.user
                },
                cxTranscripts: [],
                setCxTranscripts : (transcripts) => {
                    this.setState({cxTranscripts: transcripts});
                }
            };
        }
        catch(e) {
        }
    }

    
    render(){
        return(
            <FirebaseContext.Provider value={this.state}>
                {this.props.children}
            </FirebaseContext.Provider>
        );
    }
}