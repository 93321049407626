import React from 'react';
import UserHomepage from "./UserHomepage";
import AdminHomepage from "./AdminHomepage";

const Home =  (props) => {
    const {user} = props;

    const {handleLogout} = props;

    // const changeDisplayName = async () => {
    //     user.updateProfile({
    //         displayName: user.displayName+'1'
    //       });
    // } 
    return (
        <div className='hero'>

            <div className="heroContent">              
                {
                    user && (user.email === 'tric@tricu.ro' || user.email === 'sefulaparapanta@airsports.ro') ? <AdminHomepage handleLogout={handleLogout} user={user}/> : <UserHomepage handleLogout={handleLogout} user={user}/>
                }
            </div>

            
        </div>
    );
}


export default Home;