  import firebase from 'firebase';


  var firebaseConfig = {
    apiKey: "AIzaSyCb91O9nfoGr-UsV5_hZzLe4B9LF6xO_po",
    authDomain: "airsports-f13f8.firebaseapp.com",
    projectId: "airsports-f13f8",
    storageBucket: "airsports-f13f8.appspot.com",
    messagingSenderId: "946107096418",
    appId: "1:946107096418:web:8c19f26c4050dabcb6e03b"
  };
  // Initialize Firebase
  const fire = firebase.initializeApp(firebaseConfig);

//   module.exports = {
//     fire
//   };
export default fire;