import React, {useState, useContext, useEffect} from 'react';
import Quiz from "./Quiz";
import { useQuiz } from "../hooks/useQuiz";
import FirebaseContext from '../contexts/firebase/firebaseContext';
import {Modal, Button, Toast, Form} from 'react-bootstrap';
import ShowTranscript from './Result/ShowTranscript';
import config from '../config/config';
import NavigationBar from './NavBar';
console.log('config:: ', config);
const UserHomepage = (props) => {
    const context = useContext(FirebaseContext);
    const {
        user, 
        firebaseService, 
        // cxTranscripts, 
        // setCxTranscripts
    } = context;

    const [transcripts, setTranscripts] = useState([]);
    const [id, setId] = useState(1);
    const [quiz, loading] = useQuiz();
    const [category, setCategory] = useState('choose');
    const [questionsPerSet, setQuestionsPerSet] = useState(10);
    const [numberOfQuestionsInCategory, showCategoryQuestionsNumber] = useState(0);
    // const [maxQuetionsPerSet, setMaxQuestionsPerSet] = useState(10);
    console.log('quiz::: ', quiz);
    
    const [show, setShow] = useState(false);
    const [modalTranscript, setModalTranscript] = useState(false);
    const [disabledModal, setDisableModal] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (transcript) => {
        setModalTranscript(transcript);
        if (!disabledModal) {
            setShow(true);
        }
        else {
            // alert('Transcripts are disabled during tests!!');
            setShowToast(true);
        }
    };



    const choseOnlyFromCategory = (e) => {
        console.log('choseOnlyFromCategory:: ', e.target.value);
        const categoryQuestionsNumber = quiz.questions.filter(q => q.category === e.target.value).length;
        console.log('categoryQuestionsNumber:: ', categoryQuestionsNumber);
        setCategory(e.target.value);      
        showCategoryQuestionsNumber(categoryQuestionsNumber);
    };

    const changeQuestionsPerSet = (e) => {
        setQuestionsPerSet(e.target.value);
    }

    const randomizeQuestions = (category) => {
        const questions = category !== 'choose' ? quiz.questions.filter(q => q.category === category) : quiz.questions;
        // const clonedQuizz = {...quiz};
        const sliceSize = questionsPerSet > questions.length ? questions.length : questionsPerSet;
        // const {questions} = clonedQuizz;
        const shuffledQuestions = questions.sort(() => 0.5 - Math.random());
        const selectedQuestions = shuffledQuestions.slice(0, sliceSize);
        console.log('selectedQuestions:: ', selectedQuestions);
        return selectedQuestions;
    };

    useEffect(() =>{
        const getTranscripts = async () => {  
            const transcripts = await firebaseService.getFilteredRowsByCondition('transcripts', ["userUid", "==", user.uid], 5);
            // const transcripts = [];
            console.log('getTranscripts::  ',transcripts);
            transcripts.length && setTranscripts([...transcripts]);
            // setCxTranscripts([...transcripts]);
        };
        getTranscripts();
        
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
        [id] //[user.uid]
    );

    // useEffect(() =>{
    //     const getTranscripts = async () => {  
    //         const transcriptsss = await firebaseService.getFilteredRowsByCondition('transcripts', ["userUid", "==", user.uid], 1);

    //         transcriptsss.length && setTranscripts([...transcripts, ...transcriptsss]);
    //         setCxTranscripts([...cxTranscripts, ...transcriptsss]);
            
    //     };
    //     getTranscripts();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [id]);

    
    return (
        <div className="homeContainer">
            <NavigationBar user={props.user} handleLogout={props.handleLogout}/>
            {loading ? (
                <span>Loading...</span>
            ) : (
                <div className="homeContentContainer">
                    <div className="questionHolder">
                        <div className="categoryChosser" style={{display: !disabledModal ? 'block' : 'none'}}>
                            <div className="centerRow">
                                <Form.Group>
                                    <Form.Control as="select" required onChange={choseOnlyFromCategory}  name="type">
                                    <option defaultValue="choose">Choose...</option>
                                    {
                                        // quiz.questions.map(q => <option value={q.category}>{q.category}</option>) //unique values
                                        // [...new Set(quiz.questions.map(q => q.category) )].map((category,i) => <option value={category} key={i}>{category}</option>)
                                        [...new Set(quiz.questions.map(q => q.category) )].sort((a,b) => {
                                            console.log('a:: ',a, ' b::: ',b);
                                            var nameA = a.toUpperCase(); // ignore upper and lowercase
                                            var nameB = b.toUpperCase(); // ignore upper and lowercase
                                            if (nameA < nameB) {
                                              return -1;
                                            }
                                            if (nameA > nameB) {
                                              return 1;
                                            }
                                          
                                            // names must be equal
                                            return 0;
                                        }).map((category,i) => <option value={category} key={i}>{category}</option>)
                                        
                                    }
                                    </Form.Control> 
                                </Form.Group>
                            </div>
                            <div className="centerRow">
                                <Form.Group>
                                    {/* <input type="range" step="5" min="0" defaultValue="10"/> */}
                                    <input type="number" defaultValue={questionsPerSet} min="5" step="5" onChange={changeQuestionsPerSet}  /> of {numberOfQuestionsInCategory || quiz.questions.length}
                                </Form.Group>
                            </div>
                        </div>


                        {/* <Quiz key={id} restart={() => setId(id + 1)} {...quiz} title={category !== 'choose' ? `${category} questions` : quiz.title} questions={category !== 'choose' ? quiz.questions.filter(q => q.category === category) : quiz.questions} isModalDisabled={setDisableModal} /> */}
                        <Quiz key={id} restart={() => setId(id + 1)} {...quiz} title={category !== 'choose' ? `${category} questions` : quiz.title} questions={randomizeQuestions(category)} isModalDisabled={setDisableModal} />
                    </div>
                    
                    {!disabledModal && <div className="transcriptsHolder">
                        <ul>
                        {
                            transcripts.map((transcript,i) => <li key={i} onClick={() => handleShow(transcript)} >
                                <Button variant={transcript.score < config.minScore ? "outline-danger" : "outline-success"} style={{border:'none'}}>
                                    Scored {transcript.score} : {transcript.category}  @ {transcript.date}
                                </Button>
                            </li>).reverse()
                        }
                        </ul>
                    </div>}

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header>
                            <Modal.Title>{modalTranscript.score} : {modalTranscript.category} @ {modalTranscript.date}</Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                                {modalTranscript && <ShowTranscript transcript={modalTranscript.records}/>}
                            </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Toast className="autoHideToast" onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                        <Toast.Body>Transcripts are disabled during tests!!</Toast.Body>
                    </Toast>
                </div>
            )}
        </div>
    );
}

export default UserHomepage;