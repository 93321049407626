import fire from '../config/firebaseConfig';
import firebase from 'firebase';
let firebaseService = false;

class FireService {
    constructor() {
        this.fire = fire;
        this.db = fire.firestore();
        this.storage = fire.storage();
        this.auth = fire.auth();
        this.insertNewRow = this.insertNewRow.bind(this);
        this.getAllRows = this.getAllRows.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.getFilteredRowsByCondition = this.getFilteredRowsByCondition.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.getLastRow = this.getLastRow.bind(this);
    }

    async insertNewRow(data, collectionName, documentName) {
        const colletionRef = this.db.collection(collectionName);
        return await colletionRef.doc(documentName).set(data);
    }

    async getAllRows(collectionName) {
        const rows = [];
        const collectionRef = this.db.collection(collectionName);
        const collectionDataSnapshot = await collectionRef.get();

        collectionDataSnapshot.forEach(doc => {
          const docData = doc.data();
          rows.push(docData);
        }); 
    
        return(rows);   
    };    

    async getFilteredRowsByCondition(collectionName, condition=[], limit=null) {
        console.log('condition:: ', condition);
        const rows = [];
        const collectionRef = this.db.collection(collectionName);      
        let collectionDataSnapshot = [];
        if (limit) {
            collectionDataSnapshot = await collectionRef.where(...condition).orderBy('date', 'desc').limit(limit).get();
        }
        else {
            collectionDataSnapshot = await collectionRef.where(...condition).get();
        }
        
        
        collectionDataSnapshot.forEach(doc => {
          const docData = doc.data();
        //   console.log('doc.data():: ', doc.data());
          rows.push(docData);
        }); 
        console.log('collectionDataSnapshot.length:: ', rows.length);
        return(rows.reverse());   
    }

    async deleteRow(documentName, collectionName) {
        console.log('documentName, collectionName:: ', documentName, collectionName);
        const collectionRef = this.db.collection(collectionName);
        await collectionRef.doc(documentName).delete();
        return true;
    }

    async getLastRow(collectionName) {
        const rows = [];
        const collectionRef = this.db.collection(collectionName);
        // const collectionDataSnapshot = await collectionRef.where("stamp", ">", 1621363183);
        const collectionDataSnapshot = await collectionRef.orderBy("stamp", "desc").limit(1).get();
//         .orderBy("stamp", "asc")
//         .collection("v")
// .where("stamp", ">", 1621363183)
// .orderBy("stamp", "asc")

        collectionDataSnapshot.forEach(doc => {
            const docData = doc.data();
            rows.push(docData);
          }); 

          return(rows);   
    }


    async uploadFile(file, stringToPrepend2FileName, remotePath) {
        return new Promise((resolve, reject) => {
            const fileNameToArray = file.name.split('.');
            const ext = fileNameToArray.slice(-1)[0];
            const newFileName = `${stringToPrepend2FileName}_${Math.round((new Date()).getTime() / 1000)}.${ext}`;
            console.log('file:: ', newFileName);
            // file.name = newFileName;
            
            const storageRef = this.storage.ref();
            var uploadTask = storageRef.child(`${remotePath}/${newFileName}`).put(file);
                    // Register three observers:
            // 1. 'state_changed' observer, called any time the state changes
            // 2. Error observer, called on failure
            // 3. Completion observer, called on successful completion
            uploadTask.on('state_changed', 
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    console.log('this.fire:: ', this.fire);
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        default:
                            break;                            
                        }

                }, 
                (error) => {
                    // Handle unsuccessful uploads
                    reject();
                    
                }, 
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        resolve(downloadURL);
                    });
                }
            );
        });
    }


}




function initFirebaseService() {
    if (!firebaseService) {
        firebaseService = new FireService();
    }
    return firebaseService;
}


export default initFirebaseService();