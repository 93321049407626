import { useState, useEffect, useContext } from "react";
// import { getQuiz } from "../api/quizApi";
import FirebaseContext from '../contexts/firebase/firebaseContext';
import config from '../config/config';
const useQuiz = () => {
  const context = useContext(FirebaseContext);
  const [quiz, setQuiz] = useState({});
  const [loading, setLoading] = useState(true);

  const loadQuiz = async () => {
    // const quizObj = await getQuiz();
    let quizzQuestions = [];
    const lastFoundVersion = await context.firebaseService.getLastRow('v');
    const version = localStorage.getItem('v') || 0;
    if (version) {
      const retreivedQuestions = localStorage.getItem('questions');
      quizzQuestions = retreivedQuestions ? JSON.parse(retreivedQuestions) : [];
    }

    if (lastFoundVersion.length && lastFoundVersion[0].stamp && version < lastFoundVersion[0].stamp) {
        console.log('we need to update');
        quizzQuestions = await context.firebaseService.getAllRows('questions'); //aici luam din firebase 
        localStorage.setItem('questions', JSON.stringify(quizzQuestions));
        localStorage.setItem('v', lastFoundVersion[0].stamp);
    }
    

    const wantedScore = config.minScore;
    const quizObj = {
      id: 1,
      title: "Random questions",
      introMessage: `You need a score of ${wantedScore} to pass this test.`,
      passMessage: "Congratulations, you passed!",
      failMessage: "You failed the quiz.",
      minScore: wantedScore,
      questions: quizzQuestions
    };

    setQuiz(quizObj);
    setLoading(false);
  };

  useEffect(() => {
    loadQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [quiz, loading];
};

export { useQuiz };
