import React from 'react';
import logo from '../pics/AirsportsLogo.png';
const Login = (props) => {
    const  {email, setEmail, password, setPassword, handleLogin, handleSignUp, hasAccount, setHasAccount, emailError, passwordError} = props;

    return(
        <section className="login">
            <div className="loginContainer">
                <img src={logo} alt="logo" className="logo"/>
                <br/>
                <label>Usermame</label>
                <input type="text" value={email} onChange={e => setEmail(e.target.value)} required autoFocus />
                <p className="errorMsg">{emailError}</p>
                <label>Password</label>
                <input type="password" value={password} onChange={e=> setPassword(e.target.value)} required/>
                <p className="errorMsg">{passwordError}</p>
                <div className="btnContainer">
                    {
                        hasAccount ? (
                                        <>
                                            <button onClick={handleLogin}>Sign In</button>
                                            <p>Don't have an account? <span onClick={() => setHasAccount(!hasAccount)}>Sign Up</span></p>
                                        </>
                                    ) 
                                :   (
                                        <>
                                            <button onClick={handleSignUp}>Sign Up</button>
                                            <p>Have an account? <span onClick={() => setHasAccount(!hasAccount)}>Sign In </span></p>
                                        </>
                                    )
                    }
                </div>
            </div>
        </section>
    );
}

export default Login;