import React, {useState} from 'react';
import {Form, Col, Button, ListGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Formik } from 'formik';

// function requireAtLeastOneCorrectAnswer(schema, message) {
//     return this.test('select at least one correct answer!', (value) => {
//         if (Array.isArray(value) && value.some(a => a.correct === true))
//     });
// }
// yup.addMethod(yup.array, 'requireAtLeastOneCorrectAnswer', requireAtLeastOneCorrectAnswer);
// declare module 'yup' {
//     interface ArraySchema<T> {
//         requireAtLeastOneCorrectAnswer<T>(message?: string): ArraySchema<T>
//     }
//   }

const schema = yup.object({
  text: yup.string().required(),
  type: yup.string().required(),
  category: yup.string().required(),
//   answers: yup.array().min(2).required()
});



const QuestionForm = (props) => {
    const {newQuestionHandler} = props;
    const [newAnswer, setNewAnswer] = useState('');
    const [currentAnswers, setCurrentAnswers] = useState([]);
    const [qType, setQType] = useState('');
    const [qPic, setQpic] = useState(false);

    console.log('currentAnswers:: ', currentAnswers);

    const globalqTypeHandler = (e) => {
        setQType(e.target.value);
    };

    const handleNewAnswer = (e) => {
        console.log('handleNewAnswer:: ', e.target.value);
        setNewAnswer(e.target.value);
    };

    const addNewAnswer = (values, errors) => {
        console.log('add new answer', values);
        let key = 'correct';

        if (values.type === "OrderSelect") {
            console.log('im here:: ');
            key = 'correctOrder';
        }


        if (newAnswer !== '' && values.type !== '') {
            setCurrentAnswers(
                [
                    ...currentAnswers,
                    {
                        text: newAnswer,
                        [key]: key === 'correctOrder' ? currentAnswers.length+1 : false
                    }
                ]);
                setNewAnswer('');
        }
        else {
            console.log('da erroare ca type nu a fost ales SAU nimic nu afost completat la raspuns');
            errors.newAnswer = 'No question type was selected';
        }

    };

    const deleteThisAnswer = (i) => {
        console.log('delete answer i:: ', i);
        const tempAnswers = [...currentAnswers];
        tempAnswers.splice(i,1);
        const reindexTempAnswer = tempAnswers.map((a,i) => {
            if (qType === "OrderSelect") {
                a.correctOrder = i+1;
            }
            return a;
        } );
        setCurrentAnswers([...reindexTempAnswer]);

    }

    const toggleCorrectAnswer = (e) => {
        console.log('toggleCorrectAnswer:: ', e.target);
        const tempAnswers = [...currentAnswers];
        console.log('tempAnswers[e.target.name].correct:: ', tempAnswers[e.target.value].correct, e.target.checked);
        
        if (qType === "SingleSelect") {
            tempAnswers.forEach(answer => answer.correct = false);
        }

        tempAnswers[e.target.value].correct = e.target.checked;
        console.log('tempAnswers[e.target.name].correct111:: ', tempAnswers);
        setCurrentAnswers([...tempAnswers]);

    }
    const ref = React.useRef();

    return (
        <Formik
          validationSchema={schema}
          onSubmit={(values, {resetForm, setFieldValue, setErrors}) => {

            // same shape as initial values
            console.log('submit values:: ', values);
            console.log('is there at least one correct answer:: ', currentAnswers.some(a => a.correct === true));
    
            if (qType !== '' && currentAnswers.some(a => a.correct === true) && currentAnswers.length > 1) {
                console.log('post to firebase any other type:: ', {...values, answers:currentAnswers, picture: false});
                setQpic(false);
                setFieldValue("picture", false);
                // resetForm();
                ref.current.value = "";
                newQuestionHandler({...values, answers:currentAnswers, picture: values.picture || false});
            }
            else if (qType === 'OrderSelect' && currentAnswers.length > 1){
                console.log('post to firebase OrderSelect type:: ', {...values, answers:currentAnswers, picture: false});
                setQpic(false);
                setFieldValue("picture", false);
                // resetForm();
                ref.current.value = "";
                newQuestionHandler({...values, answers:currentAnswers, picture: values.picture || false});
            }
            else {
                console.log('show error for no correct answers selected ');
                setErrors({noSelectedAnswers: 'depending of the chossen question type you need at least two answers and one selected as the correct answer'}) ;
            }

          }}
          initialValues={{
            text: '',
            type: '',
            category: '',
            answers: [],
            picture: false
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                  
                {console.log('errors::', errors )}
                <Form.Group controlId="question.type" as={Col} md="3">
                    <Form.Label>Question type:</Form.Label>
                    <Form.Control as="select" required onChange={(e) => {globalqTypeHandler(e);setCurrentAnswers([]);return handleChange(e);}}  name="type">
                        <option>Choose...</option>
                        <option>SingleSelect</option>
                        <option>MultiSelect</option>
                        <option>OrderSelect</option>
                    </Form.Control>
                </Form.Group>                

                <Form.Group as={Col} md="3" controlId="question.category">
                  <Form.Label>Category:</Form.Label>
                    <Form.Control as="select" required onChange={handleChange('category')}>
                        <option>Choose...</option>
                        <option>Meteorologie</option>
                        <option>Navigatie</option>
                        <option>legislatie</option>
                        <option>Cunoasterea aeronavei</option>
                        <option>Performante de zbor</option>
                        <option>Performante umane</option>
                        <option>Proceduri operationale</option>
                        <option>Principile zborului</option>

                        <option>Meteorologie2023</option>
                        <option>Navigatie2023</option>
                        <option>legislatie2023</option>
                        <option>Cunoasterea aeronavei2023</option>
                        <option>Performante de zbor2023</option>
                        <option>Performante umane2023</option>
                        <option>Proceduri operationale2023</option>
                        <option>Principile zborului2023</option>                        
                    </Form.Control>
    
                  {/* <Form.Control.Feedback type="invalid" tooltip>
                    {errors.city}
                  </Form.Control.Feedback> */}
                </Form.Group>

                <Form.Group as={Col} md="6">
                    <Form.File
                        className="position-relative"
                        name="file"
                        label="File"
                        // onChange={handleChange}
                        onChange={async (event) => {
                            setFieldValue("picture", event.currentTarget.files[0]);

                            let reader = new FileReader();

                            const pictureReader = async (file) => {
                                return new Promise((resolve, reject) => {
                                    
                                    reader.onloadend = () => {
                                        resolve(reader.result);
                                      };
                                    reader.onerror = (err) => {
                                        reject(err);
                                    }
                                    reader.readAsDataURL(file);
                                });
                            };

                            if (event.currentTarget.files[0]) {
                                const readerResult = await pictureReader( event.currentTarget.files[0]);
                                setQpic(readerResult);
                                event.currentTarget.files = [];
                                
                            }
                                                       
                          }}
                        isInvalid={!!errors.file}
                        feedback={errors.file}
                        id="validationFormik107"
                        feedbackTooltip
                        ref={ref}
                    />
                    
                </Form.Group>

              </Form.Row>
              <Form.Row>
                <div className="thumbnailContainer">
                    {qPic && <img src={qPic} className="picThumbnail" alt="thumb"/>}
                </div>
                
              </Form.Row>

              <Form.Row>

                <Form.Group controlId="question.body"  as={Col}>
                    <Form.Label>Question:</Form.Label>
                    <Form.Control as="textarea" rows={3} value={values.text} onChange={handleChange('text')}/>
                </Form.Group>


                
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                    {/* <Form.Label>New answer</Form.Label> */}
                    <Form.Control type="text" placeholder="Enter new answer" onChange={handleNewAnswer} value={newAnswer}/>
                </Form.Group>
                <Form.Group as={Col}>
                    {/* <Form.Label>Add answer</Form.Label> */}
                    <Button onClick={() => addNewAnswer(values, errors)}>+</Button>
                </Form.Group> 
                
              </Form.Row>

              <Form.Row>

                  <div className="answersContainer">
                    Select correct answers:
                    {currentAnswers.map((answer, i) => 
                        <Form.Group  controlId="formBasicCheckbox" key={i} >
                            <Form.Row className="answerRow">
                                <Form.Group as={Col} md="9">
                                    {!answer.correctOrder ? (
                                        <Form.Check type="checkbox" label={answer.text} onChange={toggleCorrectAnswer} value={i} checked={answer.correct}/>
                                    ) : (
                                        <div >{answer.text} - index {answer.correctOrder}</div>
                                    )} 
                                </Form.Group>
                                <Form.Group as={Col}  md="1">
                                    <Button className="btn-warning btn-sm" onClick={(e) => deleteThisAnswer(i)}>-</Button>                                    
                                </Form.Group>                                


                            </Form.Row>

                        </Form.Group>
                        
                    )}
                  </div>
                 
              </Form.Row>
              <Form.Row>
                  <Form.Group>
                    <ListGroup>
                        {errors && (Object.values(errors).map((error,i) => <ListGroup.Item key={i} variant="danger">{error}</ListGroup.Item>))}  
                    </ListGroup>
                  </Form.Group>

                  
              </Form.Row>
              <Button type="submit" className="submitQuestionButton" onClick={() => {handleSubmit();console.log('submit');}} >Submit Question</Button>
            </Form>
          )}
        </Formik>
      );
};



export default QuestionForm;