import React from "react";

const Intro = props => {
  return (
    <>
      <div className="introContainer">
        <h1>{props.title}</h1>
        <p>{props.introMessage}</p>
        <button className="btn-action submitQuestionButton" onClick={props.onStartQuiz}>
          Start Quiz
        </button>
      </div>

    </>
  );
};

export default Intro;
