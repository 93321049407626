import React, { useState } from "react";
import { addSelectedProps, shuffleAnswers } from "../../../utils/utils";
import Answer from "../Answer";

const Question = ({ question, onAnswersSubmit }) => {
  const [numSelected, setNumSelected] = useState(0);
  const [answers, setAnswers] = useState(
    addSelectedProps(
      question.randomiseAnswers
        ? shuffleAnswers(question.answers)
        : question.answers
    )
  );

  const handleAnswerClick = answerIndex => {
    let clickedAnswer = answers[answerIndex];
    if (clickedAnswer.selected === false) {
      setAnswers(
        answers.map((answer, index) =>
          answerIndex === index
            ? {
                ...clickedAnswer,
                selected: true,
                selectedOrder: numSelected + 1
              }
            : answer
        )
      );
      setNumSelected(numSelected + 1);
    } else {
      setAnswers(
        answers.map((answer, index) =>
          answerIndex === index
            ? {
                ...clickedAnswer,
                selected: false,
                selectedOrder: null
              }
            : answer.selectedOrder > clickedAnswer.selectedOrder
            ? {
                ...answer,
                selected: true,
                selectedOrder: answer.selectedOrder - 1
              }
            : answer
        )
      );
      setNumSelected(numSelected - 1);
    }
  };

  return (
    <>
      <div className="question">
        <span className="q">{question.text}</span>
        {
        answers.map((answer, index) => (<div key={index}>
          <span className="questionIndex" key={`${index}qi`}>{index}</span>
          <span className="questionText" key={`${index}qt`}>{answer.text}</span>
        </div>))
        }
      </div>
      <div className="questionPic"> 
        {question.picture && <img className="picThumbnail" alt={`${question.id} foto`} src={question.picture}/>}
      </div>
      <div className="questionAnswers">
        {answers.map((answer, index) => (
          <Answer
            key={index}
            index={index}
            type={question.type}
            {...answer}
            onClick={handleAnswerClick}
          />
        ))}
      </div>
      <div className="submitQuestionsHolder">
        <button
          className="btn btn-outline-success"
          onClick={() => onAnswersSubmit(answers)}
          disabled={!numSelected}
        >
          Submit Answer
        </button>
      </div>

    </>
  );
};

export default Question;
